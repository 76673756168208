html,
body {
  height: 100%;
}
body {
  background: #fafafa;
}

#root {
  height: 100%;
}

footer {
  text-align: center;
  color: #bbbbbb;
  margin: 1em;
  font-style: oblique;
}
.app-content {
  height: 100%;
}
.app-container {
  padding-top: 100px;
}
.app-container.table {
  padding-top: 170px;
  width: 80%;
  margin: 0 auto;
}

#root > div {
  height: 100%;
}

.ui.dimmer {
  display: flex !important;
}

.ui.modal {
  margin-top: 0;
}

.filters {
  top: 60px;
  padding: 0 20px;
  background: #fafafa;
  position: fixed;
  width: 100%;
  height: 110px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: #00b5ad;
}
a:hover {
  color: #009c95;
}

label span {
  font-style: italic;
}

.danger {
  color: red;
  font-weight: bold;
}
@media (max-width: 600px) {
  .desktop {
    display: none !important;
  }
}
@media (min-width: 600px) {
  .mobile {
    display: none !important;
  }
}

.formWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

@import "../node_modules/hamburgers/dist/hamburgers.css";
