header {
  display: flex;
  height: 60px;
  width: 100%;
  padding: 0 20px;
  position: fixed;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9em;
  color: #717171;
  top: 0;
  left: 0;
}
header > div {
  align-items: center;
  display: flex;
}

.menu-desktop {
  display: flex;
}
.header-logo {
  margin-top: -8px;
  margin-right: 20px;
}

header .link {
  color: #717171;
  margin-right: 2em;
  position: relative;
  padding-bottom: 4px;
}

header .label {
  left: 115% !important;
  top: -15px !important;
  background: #ff5177 !important;
  color: #fbfbfb !important;
  border-radius: 50% !important;
  font-size: 0.9em !important;
  box-shadow: 1px 1px 4px 1px #ececec;
}

header .active {
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #ff5177;
}
header .link:last-child {
  margin: 0;
}
.logout {
  cursor: pointer;
  border: 0;
  background: transparent;
}
.hamburger {
  z-index: 3;
}
.mobile-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 2;
}

.mobile-menu.active {
  display: block;
}

.mobile-menu .items {
  display: flex;
  flex-direction: column;
  padding: 1em;
  font-size: 2em;
}
.mobile-menu .items .link {
  margin-bottom: 2em;
  line-height: 1.1;
}
header.menu-opened {
  position: initial;
}

@media (max-width: 600px) {
  .header-logo {
    width: 70%;
    margin-top: -2px;
  }
}
