.cell-green {
  color: teal !important;
}
.no-border {
  border-left: none !important;
}
.subtotalColumn {
  width: 130px;
}
.total {
  font-size: 1.2em;
}

.quantityWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}
.quantityWrapper .buttons {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}
.quantityWrapper i.icon {
  height: initial !important;
  color: #bfbfbf;
}
.quantityWrapper i.icon:hover {
  color: #777;
}

.subtotalWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

i.trash:hover {
  color: orangered;
}
