.product-dialog-description {
  color: #7e7e7e;
  font-size: 1.2em;
  margin-bottom: 1em;
}

.modal-product-title {
  color: #ff5177;
}
.productDialogImageWrapper {
  display: flex !important;
  justify-content: center;
}

.productDialogImageWrapper img {
  max-height: 300px;
}
